<template>
    <div style="margin-top:4px">
        <div class="grid-x text-center">
          <div class="cell small-1" @click="returnDate(-1,-1)"><span class="qarrow leftarrow"></span></div>
          <div class="cell small-10 qmediumfont qbordertext" id="datedisplayed">{{$displayDate(startdatevalue,$dateformattype.datemonthday)}}</div>
          <div class="cell small-1" @click="returnDate(1,1)"><span class="qarrow rightarrow"></span></div>
        </div>
    </div>
</template>

<script>
 export default {
        name: "calendarcomponents",
        data: function () {
            return {
                startdatevalue: this.startdate,
                enddatevalue: this.enddate,
                nodays: 0
            }
        },
        mounted() {
          
        },
        props: {
            startdate: {type:Date, default: function() {return new Date()}},
            enddate: {type:Date, default: function() {return new Date()}},
            displaytype: {type:Number, default: function() {this.$calendartype.daytoggle}}
        },
        methods: {

            returnDate: function (offsetfromstartdate, offsetfromenddate) { 

                this.startdatevalue = new Date(this.startdatevalue.setDate(this.startdatevalue.getDate() + offsetfromstartdate));

                this.enddatevalue = new Date(this.enddatevalue.setDate(this.enddatevalue.getDate() + offsetfromenddate));

                if (this.enddatevalue <= this.startdatevalue) {
                        
                        this.enddatevalue = new Date(this.enddatevalue.setDate(this.startdatevalue.getDate() + 1));
                    }

                // To calculate the time difference of two dates
                var Difference_In_Time = this.enddatevalue.getTime() - this.startdatevalue.getTime();
  
                // To calculate the no. of days between two dates
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

            
                this.$emit('dataparams', { startdate: this.startdatevalue, nodays:  Difference_In_Days});
            }
        }

            }
</script>

<style scoped>

</style>