<!-- Vue component -->
<template>

      
   <div style="position:relative;margin: 5px">
        <div :class="{loader: isloading}" style="top:15em">
            <div :style="{display: isvisible}"> 
           
                <div v-for="(row,i) in infotabledata" :key="i">
                    <div v-if="row.nodata != null">
                        <nodata></nodata>
                    </div> 
               
                    <div v-if="row.error != null">
                        <div class="parent">
                            <div class="child">
                                    <p style="color:gray;line-height:100px">Something went wrong getting your data</p>
                                    <p style="color:black;line-height:20px">{{row.desc}}</p>
                            </div>
                        </div>
                    </div> 
                    
                    <div v-if="row.nodata == null && i == 0 && row.RH === 1"  >
                        <dataviewheader></dataviewheader>
                    </div>


                    <!-- first row symbols and competition -->
                    <div :class="{hyperlink: ($URLCreateTS(row.urlstub,row.img1,row.img2,row.sec2,row.prim1,row.t,row.id,row.compid)!=='.')}" @click="$checkBeforeReroute($URLCreateTS(row.urlstub,row.img1,row.img2,row.sec2,row.prim1,row.t,row.id,row.compid))" v-if="row.nodata == null">
                    
                        <div  v-if="i==0||i>0&infotabledata[i-1].sec2!=row.sec2">
                            <div class="grid-x text-left qdarkgreylink qhighlight"  style="line-height:26px">
                                <div class="cell shrink " style="padding-right:2px; padding-left:2px"><div :class="row.img1"></div></div>
                                <div class="cell shrink" v-if="row.img2!=undefined"><img class="qflagsmall" :src="'/assets/images/flags/svg/' + row.img2 + '.svg'" alt="" /></div>
                                <div class="cell auto">
                                    <span class="qmediumfont" style="padding:0px 3px 0px 8px" v-html="row.sec2"></span> 
                                    <span class="qsmallfontplus1 qgreylink" style="padding-top:0px;padding-left:5px">{{$displayDate(row.sec1,$dateformattype.daylongmonth)}}</span>
                                    
                                </div>    
    
                             
                            </div>
                        </div>
                    </div> 
                       
                    <div @click="$checkBeforeReroute($URLCreateTS(row.urlstub,row.img1,row.img2,row.sec2,row.prim1,row.t,row.id,row.compid))">
                       
                        <div class="grid-x text-left" style="padding-bottom:3px;padding-top:3px"> 
                            <div class="cell small-1 medium-1 text-center">
                                 

                                    <div class="qsmallfontplus1 qverylightgrey" style="padding-top:1px" v-if="row.sec1!=null">{{ $displayDate(row.sec1,$dateformattype.timeonly) }}</div> 
 
                            </div>

                            <div class="cell small-11 qalignleft">
                                <div class="grid-x" v-if="row.displaybodytype == null">
                                    <div class="cell auto qmediumfont" > {{ row.prim1 }}</div>
                                    
                                    <template v-if="row.seemore">
                                        <div class="cell small-1 text-right" v-on:click="showdetail(i,row.sec2)">   
                                            <span class="qlargefont" :id="'line' + i"  >▾</span>
                                        </div>
                                    </template>
                                    <template v-if="!row.seemore">
                                        <div class="cell auto show-for-medium text-right" >   
                                            <span class="qlegendfont" >{{ row.sec3 != null ? '' + row.sec3 + ' ' + langConv("Bookmakers") + ' ' + '&#x2794;' : ''}}</span>
                                        </div>
                                    </template>
                                </div>
                                
                                
                                <template v-if="row.displaybodytype == 'oddsbodytype'">
                                <div class="grid-x  text-center" >

                                        <div class="cell small-5 medium-4 qalignleft qsmallfontplus1 qpointer" > {{row.Team1}}</div>
                                    
                                        <div class="cell small-2 qoddsdescfont" v-if="row.H1 !== null" style="margin-top:-4px">{{langConv(row.H1)}}</div>
                                                           
                                        <div class="cell small-2 qoddsdescfont"  v-if="row.H2 != null && row.odd2 != ''" style="margin-top:-4px">{{langConv(row.H2)}}</div>
                                        
                                        <div v-if="row.H2 == null || row.odd2 == 0" class="cell small-2" style="margin-top:-4px"></div>

                                        <div class="cell small-2 qoddsdescfont" v-if="row.H3 !== null" style="margin-top:-4px">{{langConv(row.H3)}}</div>
                                        
                                </div>

                                <div class="grid-x  text-center" >
                                        
                                        <div class="cell small-5 medium-4 qalignleft qsmallfontplus1 qpointer">{{row.Team2}}</div>
                               
                                        <div class="cell small-2 bestodds"  v-if="row.odd1 != null" style="margin-top:-6px; height:26px">{{ $formatOddsTS(row.odd1) }}</div>
                                        
                                        <div class="cell small-2 bestodds" v-if="row.odd2 != null && row.odd2 != 0" style="margin-top:-6px; height:26px" >{{ $formatOddsTS(row.odd2) }}</div>

                                        <div class="cell small-2" v-if="row.odd2 == 0"></div>
                                        
                                        <div class="cell small-2 bestodds" v-if="row.odd3 != null" style="margin-top:-6px; height:24px">{{ $formatOddsTS(row.odd3) }}</div>

                                        <div class="cell auto hide-for-small-only standoutno " v-if="row.sec4 != null" style="margin-top:-6px; height:26px" >{{row.sec4}}</div>
                                        <div class="cell auto hide-for-small-only qsmallfont qgreylink " v-if="row.sec4 == null" style="margin-top:-8px; height:26px">{{ langConv("More") }} <span class="qarrowsmall rightarrow"></span></div>
                                        <div class="cell auto show-for-small-only qsmallfont qgreylink " v-if="row.sec4 == null" style="margin-top:-8px; height:26px"><span class="qarrow  rightarrow"></span></div>
                                </div>
                                </template>

                                <template v-if="row.displaybodytype == null">
                                <div class="grid-x text-center">
                                    <div class="cell small-10 commentary"  v-html="row.detail2"></div>
                                    <div class="cell small-2 standoutno" v-if="row.sec4 != null">{{row.sec4}}</div>
                                </div>
                                </template>

                            </div>

                        </div>
                    </div>
                    <!-- </router-link> -->
                   
                    <template v-if="loadedsearch && detail[i]">
                            <br/>
                           
                             <div v-for="(row,i) in searchresults[0].BO" :key="i">

                                <div @click="$checkBeforeReroute($URLCreateTS(row.urlstub,row.sport,row.country,row.league,row.event,row.bettype,row.entityid))" v-if="row.bettype == 'Match Odds~'">
                                   

                                    <div class="grid-x">

                                        <div class="cell small-1">
                                            <div><img class="qflagsmall" :src="'/assets/images/flags/svg/' + row.country + '.svg'" alt="" /></div>
                                        </div>
                                        <div class="cell small-9 qmediumfont qborderlink">
                                            <div v-html="$spaceandclean(row.event)"></div>
                                            <div style="font-size: small; color: gray" v-html="$spaceandclean(row.league)"></div>
                                        </div>
                                        <div class="cell small-2 medium-2">
                                            <actionbutton buttontext="More" :routerlinkpath="$URLCreateTS(row.urlstub,row.sport,row.country,row.league,row.event,row.bettype,row.entityid)"></actionbutton> 
                                        </div>
                                    </div>
                                    <div>
                                        
                                    </div>
                              
                                <br/>
                            </div>

                            </div> 
                    </template> 


                </div>
                <div id="more" style="text-align:center;padding:5px" v-if="infotabledata.length % 10 == 0"><button v-on:click="getData(10)" style="padding:12px;border:1px solid;border-color:darkgrey;color:darkgrey">{{ langConv("More Items") }}</button></div>
            </div>

        </div>
  </div>  
   
</template>

<script>
    import axios from 'axios';
    import dataviewheader from './dataviewheader.vue'
    import actionbutton from '/src/components/components/actionbutton.vue'
    import nodata from '/src/components/components/nodata.vue'
    export default {
        name: "dataviews",
        data() {
            return {

                infotabledata: [],
                isloading: true,
                isvisible: 'None',
                noitems: 10,
                detail: [],
                searchresults: [],
                loadedsearch: false,
                lastrowopened: -1
            }
            
        },
        created() {
          //get the data on load of the components
          this.getData(0);
        },
   
        watch: {
            //has the oddstype sp changed
            spname: function (n,o) {
                this.getData(0);
            }
        },

        props: {
            spname: String,
            sportid: { type: Number, default: 0 },
            selspor: [],
            popularity: { type: Number, default: 10},
            compcountry: { type: String, default: "%%"},
            competition: { type: String, default: "%%"},
            nodays: {type: Number, default: 1},
            dateformat: {type: Object, default: () => ({ weekday: 'long', short: '2-digit', month: 'long'})},
            startdate: {type: Date, default: function() {return new Date()}},
      
        },

        components: {

            dataviewheader,
            actionbutton,
            nodata

            
        },
 
        methods: {

            showdetail: function(row, val) {
             

                if (this.lastrowopened != -1 && this.detail[this.lastrowopened] == true && this.lastrowopened != row) {this.$set(this.detail, this.lastrowopened, false); document.getElementById('line' + row).innerText = "Close ▴";}

                if (this.detail[row] != true) {
                    this.searchresultsData(val);
                    this.$set(this.detail, row, true);
                    document.getElementById('line' + row).innerText = "▴";
                    
                    
                } else {
                    this.$set(this.detail, row, false); 
                    document.getElementById('line' + row).innerText = "▾";
                }
                
                this.lastrowopened = row;
            },
           
            // get data for Most Popular
            getData: function (increment) {
                
                this.isloading = true;
                this.isvisible = "none"; 
                this.noitems += increment;
                var _self = this;

               var wcfformattedstartdate = '/Date(' + this.startdate.getTime() + ')/';

                axios.post(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/OddsAnalysis",  {       
                            spname: this.spname,
                            sport: this.sportid,
                            noitems: this.noitems,
                            country: this.$store.state.countryname,
                            popularity: this.popularity,
                            nodays: this.nodays,
                            compcountry: this.compcountry,
                            competition: this.competition,
                            selectedbookmakers: JSON.stringify(this.$store.state.sb),
                            selectedsports: JSON.stringify(this.selspor),
                            email: this.$store.state.email,
                            startdate: wcfformattedstartdate }).then((response) => {
                  
                    this.infotabledata = JSON.parse(response.data.OddsAnalysisResult)[0].BO;
                    //has any data been returned then
                    if (this.infotabledata == null) this.infotabledata = JSON.parse('[{"nodata":"true"}]');

                    if (this.noitems == Object.keys(this.infotabledata).length && this.noitems > 10) {
                         this.$nextTick(() => document.getElementById("more").scrollIntoView());
                      
                    }
                     this.$emit('dvchanged');

                }).catch(function(error){ 

                    var errmsg = ""

                    if (error.response.data!=null) { errmsg = error.response.data; }  

                    _self.infotabledata = JSON.parse('[{"error":"true","desc": "' + errmsg + '"}]');
  
                
           
                }).finally(()=>{
                    
                    this.isloading  = false;
                    this.isvisible = "block";  

                });
            },

        }

  }
</script>



<style scoped>




    .commentary { color:green; font-size:small}

    /* the D stands for Deep, when a class is used inside a v-html statement */
    .commentary >>> .DordOdd{
        display: inline-block;font-size: small; color: black; font-weight:500;  background-color: #F5F5F5;Padding:2px; border-radius: 5px
    }

    .commentary >>> .DavgOdd{
        display: inline-block;font-size: small; color: black; font-weight:500;  background-color: lightgreen;Padding:2px; border-radius: 5px
    }

     .commentary >>> .DbestOdd{
        display: inline-block;font-size: small; color: black; font-weight:500;  background-color: #FFFF8F;Padding:2px; border-radius: 5px
    }

    .lowestodds {
        font-size: small; color: black; font-weight:500;  background-color: paleturquoise;padding:1px; border-radius:5px
    }


    .standoutno {
        font-size: small; color: black; font-weight:700; border-radius:5px
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .parent {
        position: relative;
        height: 50px;
    }
    .child {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    br {
        line-height: 10px;
    }

    .hyperlink {
        font-weight:bold
    }
  
</style>