  <template>
  <div class="qmediumfont">
      <slot name="full" v-if="searchtype == 'full'">

            <br/>
            <div class="cell grid-container">
             

                <div class="grid-x grid-margin-x">
                    <div class="cell auto "><autocomplete ref="searchdescval" :search="searchTitle" :get-result-value="getResultValue" :placeholder="langConv('find teams, competitions and more')"></autocomplete></div>
                    <div class="cell medium-4 hide-for-small-only">
                        
                       
                        <div class="small button-group ">
                            <input type="radio" id="a1" v-on:click="period='day'" name="a-group" checked>
                            <label class="button secondary" for="a1">{{ langConv('Day') }}</label>
                            <input type="radio" id="a2" v-on:click="period='week'" name="a-group">
                            <label class="button secondary" for="a2">{{ langConv('Week') }}</label>
                            <input type="radio" id="a3" v-on:click="period='month'" name="a-group">
                            <label class="button secondary" for="a3">{{ langConv('Month') }}</label>
                            <input type="radio" id="a4" v-on:click="period='year'" name="a-group">
                            <label class="button secondary" for="a4">{{ langConv('Year') }}</label>
                        </div>
                  
                 

                    </div>


                    
                    <div class="cell shrink">
                            <div class="switch small">
                      
                            <input class="switch-input" id="tinySwitch" type="checkbox" name="exampleSwitch" v-on:click="changeChart()">
                            <label class="switch-paddle" for="tinySwitch">
                                <span class="show-for-sr">Tiny Sandwiches Enabled</span>
                                <span class="switch-active" aria-hidden="true">Line</span>
                                <span class="switch-inactive" aria-hidden="true">Bar</span>
                            </label>
                            
                            </div>    
                    </div>

              

                    <div class="cell shrink show-for-small-only"><button type="button" class="button tiny" v-on:click="checkValues()">{{ langConv('Redraw')}}</button></div>
                    <div class="cell shrink show-for-medium"><button type="button" class="button small" v-on:click="checkValues()">{{ langConv('Redraw')}} Graph</button></div>

                    <div class="cell">
                    <div class="grid-x grid-margin-x">
                    <div class="cell small-4 align-self-middle hide-for-small-only">
                
                            <img class="qrequireloginimg"  :src="require('/public/assets/images/lock-26.png')" :alt="langConv('Log in to access this feature')" v-if="JSON.stringify($store.state.users) === '[]'"/>
                            {{ langConv('User') }} : 
                            <dropdown  ocname="getusers" @users_values="setUserValues" :ocnoitems="999" ocdefaultvalue="Select Users" :occloseonselect="false" :ocmultiselect="true"  :ocshowlistonstart="false" :ocheightoflist="350" ocdefaultselectedoptions="All" :oclocaldata="JSON.stringify($store.state.users)" :ocdisabled="$store.state.users.length == 0"></dropdown>   
                   
                    </div>
                    <div class="cell auto align-self-middle" > {{ langConv('Strategy') }} :
                        <dropdown ocname="retrievebetstrategies" @strategy_values="setStrategyValues" :ocnoitems="999" :ocdefaultvalue="langConv('Strategy')" :occloseonselect="false" :ocmultiselect="true"  :ocshowlistonstart="false" :ocheightoflist="350" ocdefaultselectedoptions="All"></dropdown> 
                    </div>
            
                    <div class="cell shrink">
                            {{langConv('From')}} <br/><datepicker class="calwidth" v-model="fromdate" :clearable="false" :editable="false" ></datepicker>
                    </div>
                    <div class="cell shrink">
                        {{langConv('To')}} <br/><datepicker class="calwidth" v-model="todate" :clearable="false" :editable="false"></datepicker>
                    </div> 
</div>
            </div>
</div>
          </div>
      </slot>

      <slot name="dateonly" v-if="searchtype == 'dateonly'">

            <div class="grid-x" style="padding-top:5px">
                <div class="button-group tiny cell small-10">
                    <input type="radio" id="b1" v-on:click="changeDate(-1)" name="b-group">
                    <label class="button secondary" for="b1">1{{langConv('d')}}</label>
                    <!-- <input type="radio" id="b2" v-on:click="changeDate(-3)" name="b-group">
                    <label class="button secondary" for="b2">3d</label> -->
                    <input type="radio" id="b3" v-on:click="changeDate(-7)" name="b-group">
                    <label class="button secondary" for="b3">1w</label>
                    <input type="radio" id="b4" v-on:click="changeDate(-30)" name="b-group">
                    <label class="button secondary" for="b4">1m</label>
                    <input type="radio" id="b5" v-on:click="changeDate(-90)" name="b-group">
                    <label class="button secondary" for="b5">3m</label>
                    <input type="radio" id="b6" v-on:click="changeDate(-183)" name="b-group">
                    <label class="button secondary" for="b6">6m</label>
                    <input type="radio" id="b7" v-on:click="changeDate(-364)" checked  name="b-group">
                    <label class="button secondary" for="b7">1{{langConv('y')}}</label>
                    <input type="radio" id="b8" v-on:click="changeDate(-728)" name="b-group">
                    <label class="button secondary" for="b8">2{{langConv('y')}}</label>
                    
                  
                </div>
                    <!-- switch between graph types -->
                           
                 <div class="switch tiny cell small-2">
                      
                            <input class="switch-input" id="tinySwitch" type="checkbox" name="exampleSwitch" v-on:click="changeChart()">
                            <label class="switch-paddle" for="tinySwitch">
                                <span class="show-for-sr">Tiny Sandwiches Enabled</span>
                                <span class="switch-active" aria-hidden="true">{{langConv('Line')}}</span>
                                <span class="switch-inactive" aria-hidden="true">Bar</span>
                            </label>
                            
                     </div> 
            </div>
     
      </slot>   

      <slot name="dateandsearchonly" v-if="searchtype == 'dateandsearchonly'"> 

        <div class="grid-x grid-margin-x align-justify" style="margin-top:5px">

            <div class="cell auto" style="padding-left:3px;margin-top:12px">
                <input style="max-height:32px;font-size:12px" type="search" v-model="searchdesc" :placeholder="langConv('find teams, competitions and more')"/>
                
            </div>
      
            <div class="cell shrink hide-for-small-only" style="padding-right:4px;margin-top:10px">
                <span class="hide-for-small-only" style="margin-right:4px">{{langConv('From')}}: </span>
                <datepicker class="calwidth" v-model="fromdate" :clearable="false" :editable="false" ></datepicker>
                <span class="hide-for-small-only" style="margin:0px 4px 0px 4px">{{ langConv('To')}}: </span> 
                <datepicker class="calwidth" v-model="todate" :clearable="false" :editable="false"></datepicker>
            </div>

        </div>

      </slot>
      
                     
  </div>
</template>

<script>
    import dropdown from '../navigation/dropdown.vue'
    import Autocomplete from '@trevoreyre/autocomplete-vue'
    import datepicker from "vue2-datepicker"
    import 'vue2-datepicker/index.css'
    // import actionbutton from '/src/components/components/actionbutton.vue'

    export default {
        name:'searchcriteria',
        data: function () {
            return {
                fromdate: "",
                todate: "",
                strategies: [],
                searchuser: "",
                suchanged: false,
                selectedusers: [],
                sschanged: false,
                selectedstrategies: [],
                trend: false,
                individualbets: false,
                topn: 999,
                ascend: false,
                searchdesc: "",
                charttype: "bar",
                period: "day",

            }
        },
        components: {

            datepicker,
            dropdown,
            Autocomplete,
            // actionbutton
        },
        props: {
            searchtype: String
        },
        created() {

            //set some default dates
            this.defaultDates();  

            //what sort of chart is it and  
            if (this.charttype == 'bar') {this.trend = false; this.individualbets = true; }
            if (this.charttype == 'line') {this.trend = true; this.individualbets = true; }
         
            if (this.searchtype == "dateandsearchonly") { // not a graph so just -30 day
                this.changeDate(-30);
            } else {
                this.changeDate(-396); 
            }

        

        },
        watch: {
            fromdate() {
                this.selectedValues();
            },
            todate() {
                this.selectedValues();
            },
            searchdesc() {
                this.selectedValues();
            }

        },

         methods: {

             checkValues: function () {
                    //must have at least one strategy and one user selected
                    let err = '';

                    // if (this.selectedusers.length == 0) err = err = 'Select at least one Punter\n\n';
                    if (this.selectedstrategies.length == 0) err = 'Select at least one Betting Strategy\n';
                    if (err != '') {
                        this.genericConfirm(err);
                    } else {
                        this.selectedValues();
              }
             },

             selectedValues: function () { 

                 if (this.$refs.searchdescval != undefined) this.searchdesc = this.$refs.searchdescval.value;

                 this.$emit("criteria",this.fromdate,this.todate,this.selectedusers,this.selectedstrategies,this.trend,this.individualbets,this.topn,this.ascend, this.searchdesc, this.charttype,this.period);

             },

            setStrategyValues: function(val) {
        
                this.selectedstrategies = val;
            },

            setUserValues: function(val) {
              this.selectedusers = val;
            },



            changeChart: function(val) {

                if (this.charttype == 'bar') {
                    this.charttype = 'line'
                    this.trend = true
                    this.individualbets = true
                } else {
                    this.charttype = 'bar'
                    this.trend = true
                    this.individualbets = false
                }

                this.selectedValues();

            },


            changeDate: function(val) {
                
                this.defaultDates;
                
                let d = new Date();

                //if small graph then change selected period depending on number of days selected
                if(this.searchtype == 'dateonly') {
                        this.period = 'week'
                }
                
                this.fromdate = new Date(d.setDate(d.getDate() + val));
                
                this.selectedValues();

            },

            defaultDates: function() {

                    let d = new Date()
                    this.todate = new Date(d.setDate(d.getDate()+1));
                    this.fromdate = new Date(d.setDate(d.getDate() - 30)); //last 7 days entries by default

            }, 
   
            getResultValue: function (result) {

                var value = this.strip_html_tags(result.event);
                if (result.bettype != undefined) { value = value + ' (' + result.bettype + ')'; }
                return value
            }

         }

    }
</script>

<style >


.switch-paddle::after {
    width: 1rem;
    height: 1rem;
    background: #feca05;
}

.switch-paddle {

    width: 3.5rem;
    height: 1.5rem;
    font-size: small;
    font-weight: normal;
    background:#0d0638;

}

input:checked ~ .switch-paddle {
    background: #0d0638;
}

input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: gray;
}  

input.mx-input{
  font-size: 11px;
}


</style>