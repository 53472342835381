import login from '../views/login.vue'
import home from '../views/home.vue'
import notfound from '../views/notfound.vue'
import graph from '../components/components/graph.vue'
import oddsanalysis from '../views/oddsanalysis.vue'

import languagevarianthtml from '../components/components/languagevarianthtml.vue'








export default [
    
    {path: '/',component: home,name: 'home'},
    {path: '/login',component: login,name: 'login'},
    {path: '/horsesmouth/:param1(.*)*',component:() => import('../views/fullpagehorsesmouth') ,name: 'fullpagehorsesmouth',props: true},
    {path: '/knowledgebase',component:() => import('../views/knowledgebase') ,name: 'knowledgebase',props: false},
    {path: '/Sport/:sport/:opencountry', component: () => import('../views/sport.vue'), name: 'sport', props:true},
    {path: '/Competition/:eventparams', component: () => import('../views/events.vue'), name: 'events', props:true},
    {path: '/Event/:sport/:country/:competition/:competitors/:bettype/:tpoid', component: () => import('../views/eventodds.vue'), name: 'eventodds', props:true},
    {path: '/Search/:searchdesc', component: () => import('../components/navigation/searchresults.vue') , name: 'searchresults', props:true},
   
    {path: '/Calculators/HutchDutch', component:() => import('../components/features/hutchdutchcalc.vue'), name: 'hutchdutchcalc', props:true},
    {path: '/Graph', component: graph, name: 'graph', props:true},
    {path: '/KitViewer', component: () => import('../views/kitviewer.vue'), name: 'kitviewer', props:true},
    {path: '/Calculators/GameResults', component: () => import ('../components/features/hutchdutchgameresults.vue') , name: 'hutchdutchgameresults', props:true},
    {path: '/Offers/:param1(.*)*', component:() => import('../components/adverts/offers.vue'), name: 'offers', props:true},

    {path: '/Calculators/BetResults', component: () => import( '../components/features/hutchdutchsettlement.vue') , name: 'hutchdutchbetsettlement', props:true},
    {path: '/Calculators/PendingBets', component: () => import( '../components/features/hutchdutchsettlement.vue') , name: 'pendingbets', props:true},

    {path: '/Outright/:sport/:country/:compdesc/:bettype/:orid', component: () => import('../views/outrightodds.vue'), name: 'outrightodds', props:true},  
    {path: '/OddsAnalysis', component: oddsanalysis, name: 'oddsanalysis', props:false}, 
    {path: '/Bookmakerbasics', component:() => import('../../public/assets/articles/bookmakerbasics.html'), name: 'bookmakerbasics'},

    {path: '/Aboutsite', component: languagevarianthtml, name: 'aboutsite', props:true},
    
    {path: '/License-Settings', component:() => import('../components/components/licensesettings.vue'), name: 'licensesettings'},
    {path: '/Booker-Settings', component:() => import('../components/components/bookmakersettings.vue') , name: 'bookmakersettings', props:true},
    {path: '/Timezone', component:() => import('../components/components/timezone.vue'), name: 'timezone', props:true},
    {path: '/Exchange-Settings', component:() => import('../components/components/exchangesettings.vue'), name: 'exchangesettings', props:true},
    {path: '/FeedBack', component:() => import('../components/components/feedback.vue'), name: 'feedback', props:true},

    {path: '/termsandconditions', component:() => import('../../public/assets/articles/termsandconditions.html') , name: 'termsandconditions'},
    {path: '/bookmakercomparison', component:() => import('../views/bookmakercomparison.vue'), name: 'bookmakercomparison'},
    {path: '/cookiepolicy', component:  () => import( '../../public/assets/articles/cookiepolicy.html'), name: 'cookiepolicy'},
    {path: '/privacypolicy', component:() => import(  '../../public/assets/articles/privacypolicy.html') , name: 'privacypolicy'},
    {path:'/:pathMatch(.*)', component: notfound, name: 'notfound'},
    {path: '', name: ''}
    
]
